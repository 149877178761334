import { getOr } from 'lodash/fp'

import { Chip, ChipProps } from '@mui/material'
import {
  red,
  blue,
  pink,
  purple,
  indigo,
  lightGreen,
  cyan,
  teal,
  deepOrange,
} from '@mui/material/colors'

import { MetaData } from 'Common/metadata'
import ThemaCodeFilter from 'Common/search/filter/ThemaCodeFilter'

import {
  getOptionsInputParser,
  getCombinationsInputParser,
  getBooleansInputParser,
  getDatesInputParser,
  getObjectsInputParser,
} from '../searchInputParsers'
import {
  getOptionsInputSerializer,
  getCombinationsInputSerializer,
  getDatesInputSerializer,
  getObjectsInputSerializer,
} from '../searchInputSerializers'
import {
  getOptionsOutputSerializer,
  getCombinationsOutputSerializer,
  getDatesOutputSerializer,
  getContactsOutputSerializer,
  getArrayOutputSerializer,
  getIdNameArrayOutputSerializer,
  getMissingOutputSerializer,
  getEmptyArrayOutputSerializer,
  getTimeplanOutputSerializer,
  getThemaCodesOutputSerializer,
} from '../searchOutputSerializers'
import {
  FilterConfig,
  CombinationOptionFilterConfig,
  OptionFilterConfig,
  CustomOptionFilterConfig,
} from './'
import BooleanFilter from './BooleanFilter'
import CombinationOptionFilter from './CombinationOptionFilter'
import ContactFilter from './ContactFilter'
import CustomOptionFilter from './CustomOptionFilter'
import DateFilter from './DateFilter'
import OptionFilter from './OptionFilter'
import TimeplanFilter from './TimeplanFilter'

export const optionFilter = {
  Component: OptionFilter,
  getInputParser: getOptionsInputParser,
  getInputSerializer: getOptionsInputSerializer,
  getOutputSerializer: getOptionsOutputSerializer,
  displayFormat: (value: string, filterConfig: OptionFilterConfig, metaData: MetaData) =>
    getOr(
      '?',
      'name',
      metaData[filterConfig.metaDataId].find((_) => _.id === value),
    ),
}
export const customOptionFilter = {
  Component: CustomOptionFilter,
  getInputParser: getOptionsInputParser,
  getInputSerializer: getOptionsInputSerializer,
  getOutputSerializer: getOptionsOutputSerializer,
  displayFormat: (value: string, filterConfig: CustomOptionFilterConfig) =>
    getOr(
      '?',
      'name',
      filterConfig.options.find((_) => _.id === value),
    ),
}
export const optionArrayFilter = { ...optionFilter, getOutputSerializer: getArrayOutputSerializer }
export const optionIdNameArrayFilter = {
  ...optionFilter,
  getOutputSerializer: getIdNameArrayOutputSerializer,
}
export const booleanFilter = {
  Component: BooleanFilter,
  getInputParser: getBooleansInputParser,
  getInputSerializer: getOptionsInputSerializer,
  getOutputSerializer: getOptionsOutputSerializer,
  displayFormat: (value: boolean) => (value ? 'Yes' : 'No'),
}
export const missingFilter = { ...booleanFilter, getOutputSerializer: getMissingOutputSerializer }
export const emptyArrayFilter = {
  ...booleanFilter,
  getOutputSerializer: getEmptyArrayOutputSerializer,
}
export const combinationOptionFilter = {
  Component: CombinationOptionFilter,
  getInputParser: getCombinationsInputParser,
  getInputSerializer: getCombinationsInputSerializer,
  getOutputSerializer: getCombinationsOutputSerializer,
  displayFormat: (value: Record<string, any>, filterConfig: CombinationOptionFilterConfig) =>
    filterConfig.typeConfig.keys.map((prop) => value[prop]).join(' '),
}
export const dateFilter = {
  Component: DateFilter,
  getInputParser: getDatesInputParser,
  getInputSerializer: getDatesInputSerializer,
  getOutputSerializer: getDatesOutputSerializer,
  displayFormat: (value: { min: string; max?: string }) =>
    value.max ? `${value.min} -> ${value.max}` : `${value.min}`,
}
export const contactFilter = {
  Component: ContactFilter,
  getInputParser: getObjectsInputParser,
  getInputSerializer: getObjectsInputSerializer,
  getOutputSerializer: getContactsOutputSerializer,
  displayFormat: (
    value: {
      roleId: string
      contactName: string
    },
    filterConfig: FilterConfig,
    metaData: MetaData,
  ) => {
    const contactName = value.contactName
    const roleName = getOr(
      null,
      'name',
      metaData.contactRoles.find((role) => role.id === value.roleId),
    )
    if (contactName && roleName) return `${contactName} (${roleName})`
    else if (roleName) return roleName
    else if (contactName) return contactName
    return '?'
  },
  typeConfig: {
    contactOutputId: 'contactIds',
    contactAndRoleOutputId: 'contactRoles',
    roleOutputId: 'roles',
  },
}

export const timeplanFilter = {
  Component: TimeplanFilter,
  getInputParser: getObjectsInputParser,
  getInputSerializer: getObjectsInputSerializer,
  getOutputSerializer: getTimeplanOutputSerializer,
  displayFormat: (
    value: {
      min: string
      max?: string
      activityId: string
      activityDateType: string
    },
    filterConfig: FilterConfig,
    metaData: MetaData,
  ) => {
    const activityName = getOr(
      value.activityId,
      'name',
      metaData.timePlanEntryTypes.find((entry) => entry.id === value.activityId),
    )
    const dates = value.max ? `${value.min} -> ${value.max}` : `${value.min}`
    return `${activityName.toUpperCase()} (${
      value.activityDateType === 'Planned' ? 'P' : 'V'
    }) - ${dates}`
  },
  typeConfig: {
    getOutputId: (value: { activityId: string; activityDateType: string }) =>
      `activePrintTimeplan${value.activityId}${value.activityDateType}`,
  },
  hideActiveFilterName: true,
}

export const themaCodeFilter = {
  Component: ThemaCodeFilter,
  getInputParser: getObjectsInputParser,
  getInputSerializer: getObjectsInputSerializer,
  getOutputSerializer: getThemaCodesOutputSerializer,
  displayFormat: (value: { codeDescription: string; codeValue: string }) => {
    return `${value.codeValue} - ${value.codeDescription}`
  },
}

type Color = string
const CHIP_COLORS: Color[] = [
  lightGreen[300],
  red[300],
  blue[300],
  pink[300],
  purple[300],
  indigo[300],
  cyan[300],
  teal[300],
  deepOrange[300],
]
export const FilterChip = (props: { chipColor: Color } & ChipProps) => {
  const { chipColor, ...rest } = props
  return (
    <Chip
      variant="outlined"
      style={{
        margin: '5px 5px 0 0',
        backgroundColor: chipColor,
      }}
      {...rest}
    />
  )
}
export const getFilterColorFromIndex = (index: number): Color =>
  CHIP_COLORS[index % CHIP_COLORS.length]
